import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { ModalController } from '@ionic/angular';
@Component({
  selector: 'app-evalution-form-popup',
  templateUrl: './evalution-form-popup.page.html',
  styleUrls: ['./evalution-form-popup.page.scss'],
})
export class EvalutionFormPopupPage implements OnInit {
  evalucation_data


  constructor(
    public commonService: CommonService,
    public modalCtrl: ModalController,
  ) { }

  ngOnInit() {
  }

}
