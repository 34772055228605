import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { ModalController } from '@ionic/angular';
@Component({
  selector: 'app-job-interview-popup',
  templateUrl: './job-interview-popup.page.html',
  styleUrls: ['./job-interview-popup.page.scss'],
})
export class JobInterviewPopupPage implements OnInit {

  interview_info_data;
  constructor(
    public commonService: CommonService,
    public modalCtrl: ModalController,
  ) { console.log(this.interview_info_data)}

  ngOnInit() {
  }

  ionViewDidEnter() {
    
  }

  
}
