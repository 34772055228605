import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { ModalController } from '@ionic/angular';
import { IonContent } from '@ionic/angular';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router'
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { HttpService } from 'src/app/services/http.service';
@Component({
  selector: 'app-message-pop',
  templateUrl: './message-pop.page.html',
  styleUrls: ['./message-pop.page.scss'],
})
export class MessagePopPage implements OnInit {
  message_id


  sender_student_data = null;
  sender_company_data = null;
  message_data = null

  constructor(
    public commonService: CommonService,
    private route: ActivatedRoute,
    private router: Router,
    public modalCtrl: ModalController,
    public httpService: HttpService,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
  }

  ionViewDidEnter(){
    console.log(this.message_id);
    this.httpService.GetMessageByJobApplyId({job_apply_id:this.message_id}).then((res)=>{
      console.log(res);
      if(res.result=="success" && res.data !=null){
        console.log(res.data);
        this.message_data = res.data;
        if(this.message_data.length>0){
          this.httpService.GetUserById({id:this.message_data[0].company_id}).then((company_res)=>{
            console.log({id:this.message_data.company_id});
            
            if(company_res.result=="success" && company_res.data !=null){
              this.sender_company_data = company_res.data;
              console.log(this.sender_company_data);
            }
          })
  
          this.httpService.GetUserById({id:this.message_data[0].student_id}).then((student_res)=>{
            if(student_res.result=="success" && student_res.data !=null){
              this.sender_student_data = student_res.data;
              console.log(this.sender_student_data);
            }
          })
        }

      }
    })
  }
}
