import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { ModalController } from '@ionic/angular';
@Component({
  selector: 'app-aggreement-pop',
  templateUrl: './aggreement-pop.page.html',
  styleUrls: ['./aggreement-pop.page.scss'],
})
export class AggreementPopPage implements OnInit {
  aggreement_data;
  constructor(
    public commonService: CommonService,
    public modalCtrl: ModalController,
  ) { }

  ngOnInit() {
    console.log(this.aggreement_data);
  }




}
