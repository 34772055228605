import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { ModalController } from '@ionic/angular';
@Component({
  selector: 'app-timesheet-pop',
  templateUrl: './timesheet-pop.page.html',
  styleUrls: ['./timesheet-pop.page.scss'],
})
export class TimesheetPopPage implements OnInit {

  timesheet_data;
  constructor(
    public commonService: CommonService,
    public modalCtrl: ModalController,
  ) {}

  ngOnInit() {
    console.log(this.timesheet_data);
  }

  ionViewDidEnter() {
    
  }
}
